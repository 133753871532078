import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import TransactionModal from '../components/TransactionModal';
import TagModal from '../components/TagModal';
import AddTransactionModal from '../components/AddTransactionModal';
import TransactionCategory from '../components/TransactionCategory';
import FilterTag from '../components/FilterTag';
import FilterCategory from '../components/FilterCategory';
import FilterName from '../components/FilterName';

function TransactionsPage() {
  const [transactions, setTransactions] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [openAddTransactionModal, setOpenAddTransactionModal] = useState(false);
  const [transactionId, setTransactionId] = useState(0);
  const [visibleMobileRow, setVisibleMobileRow] = useState(0);
  const [selTag, setSelTag] = useState("");
  const [selCategory, setSelCategory] = useState("");
  const [selName, setSelName] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const nameTimeout = useRef();

  useEffect(() => {
    getTransactions(pageNum, selTag, selCategory, selName);
  }, []);

  function getTransactions(page, tagId, categoryId, name) {
    const queryParams = new URLSearchParams({
      page: page || 1
    });

    if (tagId !== "" && typeof tagId !== "undefined") {
      queryParams.append('tags[]', tagId);
    }

    if (categoryId !== "" && typeof categoryId !== "undefined") {
      queryParams.append('categories', categoryId);
    }

    if (name !== "" && typeof name !== "undefined") {
      queryParams.append('s', name);
    }

    fetch('/api/transactions?' + queryParams.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Cookies.get('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        setTransactions(data.transactions);
        setPageNum(parseInt(data.page));
        setNumPages(data.numPages);
        setTotalAmount(data.totalAmount);
        setVisibleMobileRow(0);
      })
      .catch(err => console.log(err));
  }

  function getNextTransactions() {
    getTransactions(pageNum + 1, selTag, selCategory, selName);
    window.scrollTo({ top: 0, behaivour: 'smooth' });
  }

  function getPreviousTransactions() {
    getTransactions(pageNum - 1, selTag, selCategory, selName);
    window.scrollTo({ top: 0, behaivour: 'smooth' });
  }

  function setTag(tagId) {
    getTransactions(1, tagId, selCategory, selName);
    setSelTag(tagId);
    window.scrollTo({ top: 0, behaivour: 'smooth' });
  }

  function setCategory(categoryId) {
    getTransactions(1, selTag, categoryId, selName);
    setSelCategory(categoryId);
    window.scrollTo({ top: 0, behaivour: 'smooth' });
  }

  function setName(name) {
    window.clearTimeout(nameTimeout.current);
    nameTimeout.current = window.setTimeout(() => {
      getTransactions(1, selTag, selCategory, name);
      window.scrollTo({ top: 0, behaivour: 'smooth' });
    }, 1000);
    setSelName(name);
  }

  function formatDate(dateStr) {
    const dateParts = dateStr.split(/\D/);
    const date = new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
    return date.toLocaleString('default', {
      month: 'short',
      day: 'numeric',
    });
  }

  function updateCategory(transactionId, categoryId) {
    fetch('/api/transactions/' + transactionId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Cookies.get('token')
      },
      body: JSON.stringify({category: categoryId})
    })
      .then(response => response.json())
      .then(data => {
        const updatedTransactions = transactions.map((transaction) => {
          if (transaction.id === transactionId) {
            transaction.category = categoryId;
            return transaction;
          } else {
            return transaction;
          }
        });
        setTransactions(updatedTransactions);
      })
      .catch(err => console.log(err));
  }

  function editTransaction(transactionId) {
    setTransactionId(transactionId);
    setOpenTransactionModal(true);
  }

  function tagTransaction(transactionId) {
    setTransactionId(transactionId);
    setOpenTagModal(true);
  }

  function closeModal() {
    setOpenTransactionModal(false);
    setOpenTagModal(false);
    setOpenAddTransactionModal(false);
    setTransactionId(0);
  }

  function showMobileRow(transactionId) {
    if (transactionId === visibleMobileRow) {
      setVisibleMobileRow(0);
    } else {
      setVisibleMobileRow(transactionId);
    }
  }

  function renderTransactionRow(transaction) {
    const classes = [];

    if (transaction.parentId) classes.push("split");
    if (transaction.pending) classes.push("pending");
    if ((transaction.category === null || transaction.category === 123) && !transaction.pending) classes.push("uncategorized");

    return (
      <React.Fragment key={transaction.id}>
        { !transaction.children &&
          <React.Fragment key={transaction.id}>
            <tr key={transaction.id} className={classes.join(" ")} onClick={() => showMobileRow(transaction.id)}>
              <td>{formatDate(transaction.date)}</td>
              <td>
                {transaction.name}
                { transaction.tags.length > 0 && 
                  <span className="tag">{transaction.tags[0]}</span>
                }
                { transaction.tags.length > 1 &&
                  <span className="tag" title={transaction.tags.join(", ")}>+ {transaction.tags.length - 1}</span>
                }
              </td>
              <td className="desktop-col"><TransactionCategory transaction={transaction} updateCategory={updateCategory} disabled={transaction.pending} /></td>
              <td className="desktop-col">{transaction.parentId && <span className="split"></span>}</td>
              <td>{dollar.format(transaction.amount)}</td>
              <td className="desktop-col">
                {!transaction.pending &&
                  <React.Fragment>
                    <button className="btn btn-link" onClick={() => editTransaction(transaction.parentId || transaction.id)}>Split</button>
                    <button className="btn btn-link" onClick={() => tagTransaction(transaction.id)}>Tag</button>
                  </React.Fragment>
                }
              </td>
            </tr>
            <tr className="d-none">
              <td colSpan={6}>
              </td>
            </tr>
            <tr className={"mobile-row " + (visibleMobileRow === transaction.id ? 'show ' : '')}>
              <td colSpan={6}>
                <div>
                  <TransactionCategory transaction={transaction} updateCategory={updateCategory} disabled={transaction.pending} />
                  {!transaction.pending &&
                    <React.Fragment>
                      <button className="btn btn-link" onClick={() => editTransaction(transaction.parentId || transaction.id)}>Split</button>
                      <button className="btn btn-link" onClick={() => tagTransaction(transaction.id)}>Tag</button>
                    </React.Fragment>
                  }
                </div>
              </td>
            </tr>
          </React.Fragment>
        }
        { transaction.children && transaction.children.map((childTransaction) => renderTransactionRow(childTransaction))}
      </React.Fragment>
    );
  }

  return (
    <div className="col-lg-10 page-container">
      <h2>All Transactions</h2>
      <div className="row justify-content-between">
        <div className="col-lg-8 filter-col">
          <FilterTag tag={selTag} setTag={setTag} />
          <FilterCategory category={selCategory} setCategory={setCategory} />
          <FilterName name={selName} setName={setName} />
        </div>
        {(selTag || selCategory || selName) && totalAmount &&
          <div className="col-lg-2">
            <strong>Total {dollar.format(totalAmount)}</strong>
          </div>
        }
        <div className="col-lg-2">
          <button type="button" className="btn btn-primary" onClick={() => setOpenAddTransactionModal(true)}>+ New Transaction</button>
        </div>
      </div>
      <table className="table table-striped transactions-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th className="desktop-col">Category</th>
            <th className="desktop-col">Split</th>
            <th>Amount</th>
            <th className="desktop-col"></th>
          </tr>
        </thead>
        <tbody>
        {transactions.map(transaction => (
          renderTransactionRow(transaction)
        ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              <div className="d-flex justify-content-center align-items-center">
                { pageNum > 1 && <button className="btn btn-outline-primary btn-sm" onClick={getPreviousTransactions}>Previous</button> }
                { pageNum === 1 && <span></span> }
                <span>Page {pageNum} of {numPages}</span>
                { pageNum < numPages && <button className="btn btn-outline-primary btn-sm" onClick={getNextTransactions}>Next</button> }
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <TransactionModal open={openTransactionModal} transactionId={transactionId} transactions={transactions} close={closeModal} getTransactions={() => getTransactions(pageNum, selTag, selCategory, selName)} />
      <AddTransactionModal open={openAddTransactionModal} close={closeModal} getTransactions={() => getTransactions(1, "", "", "")} />
      <TagModal open={openTagModal} transactionId={transactionId} transactions={transactions} close={closeModal} getTransactions={() => getTransactions(pageNum, selTag, selCategory, selName)} />
    </div>
  );
}

export default TransactionsPage;