import React from 'react';
import Categories from '../Categories';

function FilterCategory(props) {
	function handleChange(e) {
    props.setCategory(e.target.value);
	}

	return (
    <div className="row g-3 align-items-center">
      <div className="col-auto">
        <label htmlFor="category" className="col-form-label">Category</label>
      </div>
      <div className="col-auto">
    		<select className="form-control form-control-sm" onChange={handleChange} value={props.category} id="category">
    			<option value={""} key={0}>All Categories</option>
          {Categories.map(category => (
					  <React.Fragment key={category.id}>
						  <option key={category.id} value={category.id}>{category.name}</option>
              {category.subCategories.map(subCategory => (
							  <option key={subCategory.id} value={subCategory.id}>&nbsp;&nbsp;&nbsp;{subCategory.name}</option>
              ))}
					  </React.Fragment>
				  ))}
    		</select>
      </div>
    </div>
	);
}

export default FilterCategory;