import React from 'react';

function FilterName(props) {

	function handleChange(e) {
    props.setName(e.target.value);
	}

	return (
    <div className="row g-3 align-items-center">
      <div className="col-auto">
        <label htmlFor="search" className="col-form-label">Search</label>
      </div>
      <div className="col-auto">
			<input type="text" className="form-control form-control-sm" onChange={handleChange} value={props.name} id="search" />
      </div>
    </div>
	);
}

export default FilterName;